import { Physics, Debug } from '@react-three/rapier'
import useGame from './stores/useGame.js'
import { Level } from './Level.js'
import Player from './Player.js'
import { useState } from 'react'
import Effects from './Effects.js'
import { RGBELoader } from 'three-stdlib'
import { useLoader } from '@react-three/fiber'
import {
  useGLTF,
  Center,
  Text3D,
  Instance,
  Instances,
  Environment,
  Lightformer,
  OrbitControls,
  RandomizedLight,
  AccumulativeShadows,
  MeshTransmissionMaterial
} from '@react-three/drei'
import { useControls, button } from 'leva'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from 'three'
import { useRef } from 'react'


const boxGeometry = new THREE.BoxGeometry(1, 1, 1)

const floor1Material = new THREE.MeshStandardMaterial({ color: '#111111', metalness: 0, roughness: 0 })
const floor2Material = new THREE.MeshStandardMaterial({ color: '#222222', metalness: 0, roughness: 0 })
const obstacleMaterial = new THREE.MeshStandardMaterial({ color: '#000000', metalness: 0, roughness: 3 })
const wallMaterial = new THREE.MeshStandardMaterial({ color: '#887777', metalness: 0, roughness: 0 })



const Grid = ({ number = 23, lineWidth = 0.026, height = 0.5 }) => (
    // Renders a grid and crosses as instances
    <Instances position={[0, -1, 0]}>
      <planeGeometry args={[lineWidth, height]} />
      <meshBasicMaterial color="#000000" />
      {Array.from({ length: number }, (_, y) =>
        Array.from({ length: number }, (_, x) => (
          <group key={x + ':' + y} position={[x * 2 - Math.floor(number / 2) * 2, -0.01, y * 2 - Math.floor(number / 2) * 2]}>
            <Instance rotation={[-Math.PI / 2, 0, 0]} />
            <Instance rotation={[-Math.PI / 2, 0, Math.PI / 2]} />
          </group>
        ))
      )}
      <gridHelper args={[100, 100, '#000000', '#000000']} position={[0, -0.01, 0]} />
    </Instances>
  )

export default function Experience({ children, ...props })
{
    const blocksCount = useGame((state) => state.blocksCount)
    const blocksSeed = useGame(state => state.blocksSeed)
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
    const group = useRef();

    return (
<>
        <group rotation={[0, Math.PI / -8, 0]} position={[0, 1.1, 0]}>
          {/* <Center scale={[0.8, 1, 1]} front top {...props}>
          </Center> */}
          <Grid />
        </group>
        <Physics>
            {/* <Debug /> */}
            <Level count={ blocksCount } seed={ blocksSeed } />
            <Player />
        </Physics>
        {/* <Effects /> */}
        </>
    )
}