import React from 'react'
import './style.css'
import App from './App'
import Intro from './Intro'

export default function App2({ready}) {
    return (
      <>
        {/* <Intro> */}
          <App />
        {/* </Intro> */}
      </>
  )
}