import App2 from './App2'
import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.js'
import { KeyboardControls, OrbitControls } from '@react-three/drei'
import Interface from './Interface.js'
import useRefs from 'react-use-refs'
import { createRoot } from "react-dom/client"

createRoot(document.getElementById("root")).render(<App2 />)