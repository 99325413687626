import * as THREE from 'three'
import { CuboidCollider, RigidBody } from '@react-three/rapier'
import { useMemo, useState, useRef, Suspense } from 'react'
import { useFrame, extend, useThree, useLoader } from '@react-three/fiber'
import { Float, MeshDistortMaterial, Text, useGLTF, Center, OrbitControls, Sky, shaderMaterial, Shadow, ContactShadows, Edges, Text3D, useTexture, Reflector } from '@react-three/drei'
import glsl from 'babel-plugin-glsl/macro'
import { Color, AdditiveBlending } from 'three'
import { LayerMaterial, Depth, Fresnel } from 'lamina'

// THREE.ColorManagement.legacyMode = false

const boxGeometry = new THREE.BoxGeometry(1, 1, 1)

const floor1Material = new THREE.MeshStandardMaterial({ color: '#111111', metalness: 0, roughness: 0 })
const floor2Material = new THREE.MeshStandardMaterial({ color: '#222222', metalness: 0, roughness: 0 })
const obstacleMaterial = new THREE.MeshStandardMaterial({ color: '#000000', metalness: 0, roughness: 3 })
const wallMaterial = new THREE.MeshStandardMaterial({ color: '#887777', metalness: 0, roughness: 0 })


export function Model(props) {
    const { nodes, materials } = useGLTF("/modular.gltf");
    return (
      <group {...props} dispose={null}>
        <mesh
          receiveShadow
          geometry={nodes.LOGO_def.geometry}
        //   material={ obstacleMaterial }
          rotation={[-0.1, -0.42, -0.04]}
          position={[0, 1, 0]}
        >
        <MeshDistortMaterial distort={0.3} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </mesh>
      </group>
    );
  }

  export function Control(props) {
    const { nodes, materials } = useGLTF("/controls.gltf");
    return (
      <group {...props} dispose={null}>
        <mesh
          receiveShadow
          geometry={nodes.controls.geometry}
          material={materials.controls}
          rotation={[0, 0, 0]}
          position={[0, 0.1, 0]}
          scale={3}
        >
        </mesh>
      </group>
    );
  }
  useGLTF.preload("/controls.gltf");

function Bounds(){
    return <>
        <RigidBody receiveShadow castShadow type="fixed" restitution={ 1.3 } friction={ 10 } position={[0, 0, 0]}>
        {/* <spotLight position={[10, 10, 10]} angle={0.15} penumbra={2} shadow-mapSize={[512, 512]} castShadow /> */}
        <mesh
                position={ [ 46.07, 1, 21 ] }
                rotation={[0, Math.PI / -8, 0]}
                geometry={ boxGeometry }
                material={ wallMaterial }
                scale={ [ 0.3, 2, 100.3 ] }
            />
            <mesh
                position={ [ -46.03, 1, -17.2 ] }
                rotation={[0, Math.PI / -8, 0]}
                geometry={ boxGeometry }
                material={ wallMaterial }
                scale={ [ 0.3, 2, 100.3 ] }
            />
            <mesh
                position={ [ 19.2, 1, -44.4] }
                rotation={[0, Math.PI / -8, 0]}
                geometry={ boxGeometry }
                material={ wallMaterial }
                scale={ [ 100, 2, 0.3 ] }
            />
            <mesh
                position={ [ -19.2, 1, 48.3] }
                rotation={[0, Math.PI / -8, 0]}
                geometry={ boxGeometry }
                material={ wallMaterial }
                scale={ [ 100, 2, 0.3 ] }
            />
            <mesh
                castShadow
                receiveShadow
                position={ [ 0, 0, 0.5] }
                rotation={[0, Math.PI / -8, 0]}
                geometry={ boxGeometry }
                material={ obstacleMaterial }
                scale={ [ 100, 0.1, 102 ] }
            />
            {/* <mesh
                castShadow
                receiveShadow
                position={ [ 0, 0, -10] }
                rotation={[0.2, Math.PI / -8, 0]}
                geometry={ boxGeometry }
                material={ wallMaterial }
                scale={ [ 10, 1, 10 ] }
            /> */}
            {/* <CuboidCollider
                type="fixed"
                args={ [60, 0.1, 60 ] }
                position={ [ 0, -0.1, 0 ] }
                rotation={[0, Math.PI / -8, 0]}
                restitution={ 0.2 }
                friction={ 1 }
            /> */}
        </RigidBody>
        <Model scale={2} rotation={[0.1, 0, -0.02]}/>
        <group rotation={[0, Math.PI / -8, 0]}>
        <Control position={[0, 0, 5]} />
        <Text3D     
          font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[-21, 0.1, -20.5]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`WHO WE
   ARE`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[-35, 0.1, 0]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`PARTNERSHIP`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[12, 0.1, -20.5]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`WHAT WE
  OFFER`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[28, 0.1, -35]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`CONTACT`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[1, 0.1, 10]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`MODULAR
   LABS
   CLUB`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[25, 0.1, 0]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`MEDIUM`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[-35, 0.1, -35]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`TEAM`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[-3, 0.1, 30]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`WORKS`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[-9, 0.1, 10]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`MODULAR
   LABS
  STORE`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} />
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
           size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[23, 0.1, 23]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
         {`MODULAR
   LABS 
    ART`} 
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} /> 
        </Text3D>
        <Text3D font="/font2.json"
          curveSegments={32}
          bevelEnabled
          bevelSize={0.15}
          bevelThickness={0.1}
          height={0.2}
          lineHeight={1}
          letterSpacing={1}
          size={1.5} scale={0.5} rotation={[Math.PI / -2, 0, 0]} position={[-30, 0.1, 23]} color={"black"} textAlign="center" anchorX="center" anchorY="middle">
        {`MODULAR
   LABS
     AI`}
        <MeshDistortMaterial distort={0.1} speed={1} color={"#a7f542"} metalness={2} roughness={0.7} /> 
        </Text3D>
        </group>
    </>
}

export function Level({
    count = 0
})
{
    const blocks = useMemo(() =>
    {
        const blocks = []
        for(let i = 0; i < count; i++)
        {}
        return blocks
    }, [])
    
    return <>
        { blocks.map((Block, index) => <Block key={ index } position={ [ 0, 0, - (index + 1) * 4 ] } />) }
        <Bounds />
    </>
}