import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Experience from './Experience.js'
import { KeyboardControls, OrbitControls, View, OrthographicCamera, PerspectiveCamera } from '@react-three/drei'
import Interface from './Interface.js'
import useRefs from 'react-use-refs'
import * as THREE from 'three'
import { useRef, useState, Suspense } from 'react'
import { Modal, Typography, ImageList, ImageListItem, ThemeProvider, createTheme, CssBaseline } from '@mui/material'
import { Box } from '@mui/system'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { alignProperty } from '@mui/material/styles/cssUtils'
import Pixelmania from './Pixelmania.ttf'
import PIXELLI from './PIXEL-LI.ttf'
import hoversfx from './hover.mp3'
import clicksfx from './click.mp3'

const style = {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'black',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };


  const style2 = {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '350',
    bgcolor: 'black',
    border: '2px #000',
    boxShadow: 24,
    p: 2,
  };

  const teamData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];



  const partnersData = [
    {
      img: './BYBIT.png',
      title: 'bybit',
    },
    {
      img: './CRYPTONITE.png',
      title: 'cryptonite',
    },
    {
      img: './GARE.png',
      title: 'gare',
    },
    {
      img: './HOLY.png',
      title: 'holy',
    },
    {
      img: './YOUNG.png',
      title: 'young',
    },
  ];


export default function App({ ready }) {
const audio1 = new Audio(hoversfx);
const audio2 = new Audio(clicksfx);
audio1.loop = false;
audio1.volume = 0.1;
audio2.loop = false;
audio2.volume = 0.1;
const [ref, view1, view2] = useRefs()
const boxGeometry = new THREE.BoxGeometry(1, 1, 1)
const [hoveredteam, hoverteam] = useState(false)
const [hoveredcontact, hovercontact] = useState(false)
const [hoveredabout, hoverabout] = useState(false)
const [hoveredservices, hoverservices] = useState(false)
const [hoveredpartnership, hoverpartnership] = useState(false)
const [hoveredmedium, hovermedium] = useState(false)
const [hoveredstore, hoverstore] = useState(false)
const [hoveredclub, hoverclub] = useState(false)
const [hoveredai, hoverai] = useState(false)
const [hoveredworks, hoverworks] = useState(false)
const [hoveredart, hoverart] = useState(false)
const [openteam, setOpenteam] = useState(false)
const [opencontact, setOpencontact] = useState(false)
const [openabout, setOpenabout] = useState(false)
const [openservices, setOpenservices] = useState(false)
const [openpartnership, setOpenpartnership] = useState(false)
const [openmedium, setOpenmedium] = useState(false)
const [openstore, setOpenstore] = useState(false)
const [openclub, setOpenclub] = useState(false)
const [openai, setOpenai] = useState(false)
const [openworks, setOpenworks] = useState(false)
const [openart, setOpenart] = useState(false)

return (
    <Suspense fallback={<h1>Loading...</h1>}>
    <KeyboardControls
        map={ [
            { name: 'forward', keys: [ 'ArrowUp', 'KeyW' ] },
            { name: 'backward', keys: [ 'ArrowDown', 'KeyS' ] },
            { name: 'leftward', keys: [ 'ArrowLeft', 'KeyA' ] },
            { name: 'rightward', keys: [ 'ArrowRight', 'KeyD' ] },
            { name: 'jump', keys: [ 'Space' ] },
        ] }
    >
    <div ref={ref} className="container">
      <div className="view1" ref={view1} />
      <div className="view2" ref={view2} />
        <Canvas ready={ready} dpr={[1, 2]} shadows orthographic camera={{ position: [10, 20, 20], zoom: 70, near: -100, far: 1000, bottom: -1000}}>
        <View index={1} track={view1}>
        <directionalLight shadow-camera-near={1} shadow-camera-far={10} shadow-camera-left={-100} shadow-camera-right={100} shadow-camera-top={100} shadow-camera-bottom={-100} intensity={ 1.5 }  position={[0, 10, 0]} angle={0.15} shadow-mapSize={[2048, 2048]} castShadow/>
            <color attach="background" args={['#4b4b4b']} />
            <Experience />
            <EffectComposer disableNormalPass>
                <Bloom luminanceThreshold={1} mipmapBlur />
            </EffectComposer>
            {/* <OrbitControls makeDefault/> */}
            <group rotation={[0, Math.PI / -8, 0]}>
            <mesh name={"team"}
                castShadow
                receiveShadow
                position={[-32.9, 0.1, -35.5]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={(event) => {setOpenteam(true); audio2.play()}}
                onPointerOver={(event) => {hoverteam(true); audio1.play()}}
                onPointerOut={(event) => hoverteam(false)}>
                <meshStandardMaterial color={hoveredteam ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"contact"}
                castShadow
                receiveShadow
                position={[32, 0.1, -35.5]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpencontact(true); audio2.play()}}
                onPointerOver={(event) => {hovercontact(true); audio1.play()}}
                onPointerOut={(event) => hovercontact(false)}>
                <meshStandardMaterial color={hoveredcontact ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"about"}
                castShadow
                receiveShadow
                position={[-17.3, 0.1, -20.3]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenabout(true); audio2.play()}}
                onPointerOver={(event) => {hoverabout(true); audio1.play()}}
                onPointerOut={(event) => hoverabout(false)}>
                <meshStandardMaterial color={hoveredabout ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"services"}
                castShadow
                receiveShadow
                position={[16.2, 0.1, -20.3]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenservices(true); audio2.play()}}
                onPointerOver={(event) => {hoverservices(true); audio1.play()}}
                onPointerOut={(event) => hoverservices(false)}>
                <meshStandardMaterial color={hoveredservices ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"partnership"}
                castShadow
                receiveShadow
                position={[-29.1, 0.1, -0.4]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 13, 0.2, 6 ] }
                onClick={() => {setOpenpartnership(true); audio2.play()}}
                onPointerOver={(event) => {hoverpartnership(true); audio1.play()}}
                onPointerOut={(event) => hoverpartnership(false)}>
                <meshStandardMaterial color={hoveredpartnership ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"medium"}
                castShadow
                receiveShadow
                position={[28, 0.1, -0.4]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 13, 0.2, 6 ] }
                onClick={() => {setOpenmedium(true); audio2.play()}}
                onPointerOver={(event) => {hovermedium(true); audio1.play()}}
                onPointerOut={(event) => hovermedium(false)}>
                <meshStandardMaterial color={hoveredmedium ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"store"}
                castShadow
                receiveShadow
                position={[-5, 0.1, 10.8]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenstore(true); audio2.play()}}
                onPointerOver={(event) => {hoverstore(true); audio1.play()}}
                onPointerOut={(event) => hoverstore(false)}>
                <meshStandardMaterial color={hoveredstore ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"club"}
                castShadow
                receiveShadow
                position={[5, 0.1, 10.8]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={(e) => {window.open('https://t.me/modularlabs'); audio2.play()}}
                onPointerOver={(event) => {hoverclub(true); audio1.play()}}
                onPointerOut={(event) => hoverclub(false)}>
                <meshStandardMaterial color={hoveredclub ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"ai"}
                castShadow
                receiveShadow
                position={[-26, 0.1, 23.6]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenai(true); audio2.play()}}
                onPointerOver={(event) => {hoverai(true); audio1.play()}}
                onPointerOut={(event) => hoverai(false)}>
                <meshStandardMaterial color={hoveredai ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"works"}
                castShadow
                receiveShadow
                position={[0, 0.1, 29.5]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenworks(true); audio2.play()}}
                onPointerOver={(event) => {hoverworks(true); audio1.play()}}
                onPointerOut={(event) => hoverworks(false)}>
                <meshStandardMaterial color={hoveredworks ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"art"}
                castShadow
                receiveShadow
                position={[26.9, 0.1, 23.6]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenart(true); audio2.play()}}
                onPointerOver={(event) => {hoverart(true); audio1.play()}}
                onPointerOut={(event) => hoverart(false)}>
                <meshStandardMaterial color={hoveredart ? '#5d9c0c' : '#000000'}/>
            </mesh>
            </group>
            </View>
            <View index={2} track={view2}>
            <directionalLight
                intensity={ 1.5 }  position={[0, 10, 0]} angle={0.15} shadow-mapSize={[2048, 2048]} castShadow/>
            <OrthographicCamera makeDefault position={[0, 0, 0]} zoom={5} near={-100} far={100} left={-1000} right={1000} top={1000} bottom={-1000}/>
            <Experience />
            <color attach="background" args={['#4b4b4b']} />
            {/* <OrbitControls makeDefault/> */}
            </View>
            {/* <OrbitControls makeDefault/> */}
            <group rotation={[0, Math.PI / -8, 0]}>
            <mesh name={"team"}
                castShadow
                receiveShadow
                position={[-32.9, 0.1, -35.5]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={(event) => {setOpenteam(true); audio2.play()}}
                onPointerOver={(event) => {hoverteam(true); audio1.play()}}
                onPointerOut={(event) => hoverteam(false)}>
                <meshStandardMaterial color={hoveredteam ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"contact"}
                castShadow
                receiveShadow
                position={[32, 0.1, -35.5]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpencontact(true); audio2.play()}}
                onPointerOver={(event) => {hovercontact(true); audio1.play()}}
                onPointerOut={(event) => hovercontact(false)}>
                <meshStandardMaterial color={hoveredcontact ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"about"}
                castShadow
                receiveShadow
                position={[-17.3, 0.1, -20.3]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenabout(true); audio2.play()}}
                onPointerOver={(event) => {hoverabout(true); audio1.play()}}
                onPointerOut={(event) => hoverabout(false)}>
                <meshStandardMaterial color={hoveredabout ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"services"}
                castShadow
                receiveShadow
                position={[16.2, 0.1, -20.3]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenservices(true); audio2.play()}}
                onPointerOver={(event) => {hoverservices(true); audio1.play()}}
                onPointerOut={(event) => hoverservices(false)}>
                <meshStandardMaterial color={hoveredservices ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"partnership"}
                castShadow
                receiveShadow
                position={[-29.1, 0.1, -0.4]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 13, 0.2, 6 ] }
                onClick={() => {setOpenpartnership(true); audio2.play()}}
                onPointerOver={(event) => {hoverpartnership(true); audio1.play()}}
                onPointerOut={(event) => hoverpartnership(false)}>
                <meshStandardMaterial color={hoveredpartnership ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"medium"}
                castShadow
                receiveShadow
                position={[28, 0.1, -0.4]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 13, 0.2, 6 ] }
                onClick={() => {setOpenmedium(true); audio2.play()}}
                onPointerOver={(event) => {hovermedium(true); audio1.play()}}
                onPointerOut={(event) => hovermedium(false)}>
                <meshStandardMaterial color={hoveredmedium ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"store"}
                castShadow
                receiveShadow
                position={[-5, 0.1, 10.8]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenstore(true); audio2.play()}}
                onPointerOver={(event) => {hoverstore(true); audio1.play()}}
                onPointerOut={(event) => hoverstore(false)}>
                <meshStandardMaterial color={hoveredstore ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"club"}
                castShadow
                receiveShadow
                position={[5, 0.1, 10.8]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={(e) => {window.open('https://t.me/modularlabs'); audio2.play()}}
                onPointerOver={(event) => {hoverclub(true); audio1.play()}}
                onPointerOut={(event) => hoverclub(false)}>
                <meshStandardMaterial color={hoveredclub ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"ai"}
                castShadow
                receiveShadow
                position={[-26, 0.1, 23.6]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenai(true); audio2.play()}}
                onPointerOver={(event) => {hoverai(true); audio1.play()}}
                onPointerOut={(event) => hoverai(false)}>
                <meshStandardMaterial color={hoveredai ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"works"}
                castShadow
                receiveShadow
                position={[0, 0.1, 29.5]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenworks(true); audio2.play()}}
                onPointerOver={(event) => {hoverworks(true); audio1.play()}}
                onPointerOut={(event) => hoverworks(false)}>
                <meshStandardMaterial color={hoveredworks ? '#5d9c0c' : '#000000'}/>
            </mesh>
            <mesh name={"art"}
                castShadow
                receiveShadow
                position={[26.9, 0.1, 23.6]}
                rotation={[0, 0, 0]}
                geometry={ boxGeometry }
                scale={ [ 10, 0.2, 6 ] }
                onClick={() => {setOpenart(true); audio2.play()}}
                onPointerOver={(event) => {hoverart(true); audio1.play()}}
                onPointerOut={(event) => hoverart(false)}>
                <meshStandardMaterial color={hoveredart ? '#5d9c0c' : '#000000'}/>
            </mesh>
            </group>
        </Canvas>
        </div>
        {/* <Interface /> */}
    </KeyboardControls>
    <Modal name={"team"} open={openteam} onClose={() => setOpenteam(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style2} fontFamily='Pixelmania' onClick={() => {setOpenteam(false); audio1.play()}}>
                <div className="titoli">
                    OUR TEAM AVATARS <br />
                    COMING SOON
                </div>
                    {/* <br/><Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <ImageList sx={{ width: 330, height: 400 }} cols={2} rowHeight={115}>
                        {teamData.map((item) => (
                            <ImageListItem key={item.img}>
                                <img
                                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                    </ImageListItem>))}
                    </ImageList>
                </Typography> */}
            </Box>
        </Modal>
        <Modal name={"contact"} open={opencontact} onClose={() => setOpencontact(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpencontact(false); audio1.play()}}>
                <div className="titoli">
                    CONTACTS
                </div>
                <div className='corpi'>
                    <br/> To contact us, you will not have to fill out any forms and wait for a response. Contact us directly on our social media:<br/>
                    <br/>CEO: Mirco Bandini<br/><br/>
                    <a className="App-link" target='Blank' href="https://www.instagram.com/mircothecreator"> instagram: @mircothecreator </a><br/><br/>
                    <a className="App-link" target='Blank' href="https://www.linkedin.com/in/mirco-bandini-124294246"> linkedin: mirco bandini </a><br/><br/><br/><br/>
                    Project Manager: Federico Podavini<br/><br/>
                    <a className="App-link" target='Blank' href="https://www.instagram.com/federico.podavini/"> instagram @federico.podavini </a><br/><br/>
                    <a className="App-link" target='Blank' href="https://www.linkedin.com/in/federico-podavini-b34900149"> linkedin: federico podavini </a><br/><br/><br/>
                    <a className="App-link" target='Blank' href="https://www.instagram.com/modularlabs.tech"> instagram: @modularlabs.tech </a>
                </div>
            </Box>
        </Modal>
        <Modal name={"about"} open={openabout} onClose={() => setOpenabout(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpenabout(false); audio1.play()}}>
                <div className="titoli">
                    WHO WE ARE
                </div>
                <div className='corpi'>
<br/>Modular Labs SRL is a specialized crew in software development, the creation of Blockchain-based solutions, the development of dapps, 3D, 2.0, and 3.0 websites, and the training of individuals in the realm of Blockchain technology, crypto, and NFTs through its private CLUB. Additionally, the company handles all aspects of dialogue and relationship with Artificial Intelligence. <br/>
Founded in July 2022, Modular Labs' mission is to make the opportunities offered by web 3.0 technology accessible to the general public through the formation of its own CLUB and direct B2B guidance and consultation. Specifically, the company aims to assist large and small companies in utilizing Blockchain technologies, particularly NFTs, with the goal of expanding and consolidating their business. <br/>
Modular Labs distinguishes itself through its presence in the real world, utilizing the most advanced technologies in a conscious and creative manner, with the objective of monetizing, expanding, and retaining its clients' audience. <br/>
Discover the Team at Modular Labs.
                </div>
            </Box>
        </Modal>
        <Modal name={"services"} open={openservices} onClose={() => setOpenservices(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpenservices(false); audio1.play()}}>
                <div className="titoli">
                    WHAT WE OFFER
                </div>
                <div className='corpi'>
<br/>We offer a wide range of services, including consultation and development utilizing cutting-edge Web 3.0 technology, integration of Artificial Intelligence, creation of decentralized applications and innovative websites, software development and blockchain integration, as well as expertise in Non-Fungible Tokens and cryptocurrency. 
Additionally, we specialize in 3D design, user experience and user interface design, and alliance in the Art sphere. 
We also provide training and consultation through private CLUBS and community-based instruction in NFTs, cryptocurrency, and trading. 
Our marketing and communication services include community management, creative positioning and targeting, and the establishment of strategic partnerships.
                </div>
            </Box>
        </Modal>
        <Modal name={"partnership"} open={openpartnership} onClose={() => setOpenpartnership(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style2} onClick={() => {setOpenpartnership(false); audio1.play()}}>
                <div className="titoli">
                    OUR PARTNERS<br/>
                </div>
                <ImageList sx={{ width: 330, height: 400 }} cols={2} rowHeight={115}>
                    {partnersData.map((item) => (
                    <ImageListItem key={item.img}>
                        <img
                          src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                          srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          alt={item.title}
                          loading="lazy"
                        />
                    </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </Modal>
        <Modal name={"medium"} open={openmedium} onClose={() => setOpenmedium(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpenmedium(false); audio1.play()}}>
                <div className="titoli">
                    BLOG
                </div>
            </Box>
        </Modal>
        <Modal name={"store"} open={openstore} onClose={() => setOpenstore(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpenstore(false); audio1.play()}}>
            <div className="titoli">
                STORE
            </div>
            <div className='corpi'>
<br/>Under construction.
Here you will be able to purchase many gadgets from the future, they are so advanced that it will take a bit longer for them to reach our warehouses, which are simply located in the present.
            </div>
            </Box>
        </Modal>
        <Modal name={"club"} open={openclub} onClose={() => setOpenclub(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpenclub(false); audio1.play()}}>
                <div className="titoli">
                    CLUB
                </div>
            </Box>
        </Modal>
        <Modal name={"ai"} open={openai} onClose={() => setOpenai(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpenai(false); audio1.play()}}>
                <div className="titoli">
                    AI SERVICES
                </div>
                <div className='corpi'>
<br/>We just launched a magazine run by 90% AI, we can call it a successful experiment? It'll be at the top newsstands soon.
                </div>
            </Box>
        </Modal>
        <Modal name={"works"} open={openworks} onClose={() => setOpenworks(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpenworks(false); audio1.play()}}>
                <div className="titoli">
                    WORKS
               </div>
               <div className='corpi'>
<br/>Coming Soon.
We will soon unveil what we have accomplished, both in the past and present. Patience is a virtue. Please check back soon to discover what lies behind this button.
                </div>
            </Box>
        </Modal>
        <Modal name={"art"} open={openart} onClose={() => setOpenart(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style} onClick={() => {setOpenart(false); audio1.play()}}>
                <div className="titoli">
                    ART
                </div>
                <div className='corpi'>
<br/>On the horizon.
The future is here, but not yet available. Our art gallery is almost ready, as our artists are in the process of creating.
                </div>
            </Box>
        </Modal>
    </Suspense>
)}